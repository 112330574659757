//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import uploadfp from './uploadfp'
export default {
    props: {
        rowguid: {
            type: String,
            default: ''
        }
    },
    components:{uploadfp},
    data () {
        return {
            tableData:[],
            receType:'',
            formTitle:'',
            dialogUploadfpVisible:false,
            dialogPingzhengVisible:false,
            childrowguid:'',
            imgurl:'',
            settAmount:'',
            needinvoice:'',
        }
    },
    computed: {
  
    },
    watch:{
        receType:function(n,o){
            if(!!o && n != o ){
                this.getChildList();
            }
        },
        dialogUploadfpVisible:function(n,o){
            if(!n){
                this.getChildList();
            }
        }
    },
	methods:{
        cancle:function(){
            this.$emit('close');
        },
        getChildList:function(){
            this.$get(`${this.getGetters('settUrl')}/settItem/listChild`,{
                receType:this.receType,
                rowguid:this.rowguid,
            },function(data){
                this.tableData = data.list;
                this.settAmount = data.settAmount;
            })
        },
        //导出excel
        exportList:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    receType:this.receType,
                    rowguid:this.rowguid,
                    isExport: true,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
                });
                window.open('/bhtong/settmg/settItem/listChild' + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        goDetail:function(row){
            if(this.getQuery('token')){
            this.goto({
                path: './childdetail',
                query: {
                    rowguid: row.rowguid,
                    token:this.getQuery('token')
                }
            })
            }else{
                this.goto({
                    path: './childdetail',
                    query: {
                        rowguid: row.rowguid
                    }
                }) 
            }
        },
        //开具发票
        kfp:function(row){
            this.needinvoice = this.getQuery('needinvoice') ? this.getQuery('needinvoice') : this.getGetters('user').needinvoice
            this.formTitle = '上传发票';
            this.dialogUploadfpVisible = true;
            this.childrowguid = row.rowguid;
        },
        //查看凭证
        checkPz:function(row){
            if(row.settFile){
                var search = toSearch({
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
                });
                this.imgurl =  '/bhtong/' + row.settFile + search
                this.formTitle = '查看凭证';
                this.dialogPingzhengVisible = true;
            }else{
                ShowMsg('当前清单暂无凭证','warning');
            }
        },
        //下载发票
        downloadfp:function(row){
            window.open(row.invoiceFile)
        },
        //设置机构类型
        SetReceType:function(){
            if(this.getGetters('user').registertype == 1 || this.getQuery('registertype') == 1){
                this.receType = '3';
            }else if((this.getGetters('user').registertype == 2 || this.getQuery('registertype') == 2) && (this.getGetters('user').isfxs == true  || this.getQuery('isfxs') == 'true')){
                this.receType = '2';
            }
            else{
                this.receType = '1';
            }
        }
	},
	mounted:function(){
        this.SetReceType();
        this.getChildList();
	}
}
