//
//
//
//
//
//
//
//
//
//



export default {

    props:[],
    components:{},
    data: function(){
        return{
            monthList:[
                {id:'1',month:'1月',show:false},
                {id:'2',month:'2月',show:false},
                {id:'3',month:'3月',show:false},
                {id:'4',month:'4月',show:false},
                {id:'5',month:'5月',show:false},
                {id:'6',month:'6月',show:false},
                {id:'7',month:'7月',show:false},
                {id:'8',month:'8月',show:false},
                {id:'9',month:'9月',show:false},
                {id:'10',month:'10月',show:false},
                {id:'11',month:'11月',show:false},
                {id:'12',month:'12月',show:false},
            ],
            nowMonth:''
        }
    }, 
    computed:{
       
    },
    watch:{

    },

    methods: {
        getNowMonths:function(){
            var date = new Date();
            this.nowMonth = date.getMonth() + 1
            for ( let i = 0; i < this.nowMonth; i++) { 
                this.monthList[i].show = true;
            }
        },
        addmonth:function(val){
            console.log(111,val);
        }


    },
    mounted() {
        this.getNowMonths()
        
    }
}
