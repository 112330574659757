//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page'
import applysettItem from './applysettItem'
import childlist from './childlist.vue'
import invoice from './invoice'


export default {
    mixins: [ LIST_MIXIN ],
    props:[],
    components:{applysettItem,childlist,invoice},
    data: function(){
        return{
            pgData:{
                itemStatus:'',
                settStart:'',
                settEnd:'',
            },
            tableData:[],
            dialogApplyVisible:false,
            dialogChildVisible:false,
            dialogInvoiceVisible:false,
            formTitle:'',
            rowguid:'',
            token:''
        }
    }, 
    computed:{
        valuetime:{
            get:function(){
                var startTime = this.pgData.settStart || '';
                var endtime = this.pgData.settEnd || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.settStart = '' ;
                    this.pgData.settEnd = '';
                }else{
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.settStart = startTime;
                    this.pgData.settEnd = endtime;
                }
            },
        }  
    },
    watch:{
        dialogApplyVisible: function(n, o){
            if(!n){
                this.doSearch();
            }
        },
        dialogChildVisible: function(n, o){
            if(!n){
                this.doSearch();
            }
        },
        dialogInvoiceVisible: function(n, o){
            if(!n){
                this.doSearch();
            }
        },
        'pgData.itemStatus':function(n,o){
            if(n != o){
                this.$refs.page.reload();
            }
        }
    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        //申请结算
        applyJS:function(){
            this.dialogApplyVisible = true;
            this.formTitle = '申请结算';
        },
        //删除清单
        delInventory:function(row){
            var that = this;
            ShowConfirm('删除后需重新添加结算清单，是否确定？' , 'warning', function() {
                that.$delete(that.$store.getters.settUrl + '/settItem/'+row.rowguid,{},function(data){
                    ShowMsg('删除成功','success');
                    that.doSearch();
                })
            }, function() {
                  ShowMsg('已取消删除操作','info');
            })
        },
        //跳转详情
        goDetail:function(row){
            if(this.getQuery('token')){
                this.goto({
                    path: './detail',
                    query: {
                        rowguid: row.rowguid,
                        token:this.getQuery('token')
                    }
                })
            }else{
                this.goto({
                    path: './detail',
                    query: {
                        rowguid: row.rowguid
                    }
                }) 
            }
        },
        //子清单
        opemChildlist:function(row){
            this.dialogChildVisible = true;
            this.formTitle = '子清单';
            this.rowguid = row.rowguid;
        },
        //开具发票
        kjInvoice:function(){
            this.dialogInvoiceVisible = true;
            this.formTitle = '开具发票'
        },



    },
    mounted() {

    }
}
