//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import commonTable from '../../components/common_table'
export default {
    components: {commonTable },
    props: {},
    data(){
        return{
            detailData:{},
            tableData:[],
            partnerNamelist:[],
            pgData:{
                partnerName:'',
            }
        }
    },
    computed: {
        rowguid:function(){
            return this.getQuery('rowguid')
        }  
    },
    watch:{
        'pgData.partnerName':function(n,o){
            if(n != o){
                this.getDetail(n);
            }
        }
    },
    methods: {
        getDetail:function(val){
            this.$get(this.$store.getters.settUrl + '/settItem/'+this.rowguid, {
                partnerName:val
            }, function(data) {
                this.detailData = data.settItem;
                this.tableData = data.guaOrderList;
            })
        },
        //获取合作单位列表
        getPartnerName:function(){
            this.$get(`${this.getGetters('partnerUrl')}/channelInfo/listSelect`,{},function(data){
                this.partnerNamelist = data;
            })
        },
        //导出
        exportList:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    partnerName:this.pgData.partnerName,
                    isExport: true,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
                });
                window.open('/bhtong/settmg/settItem/' + this.rowguid + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        //返回
        goBack:function(){
            this.$router.go(-1);
        }
    },
    mounted:function(){
        this.getDetail();   
        this.getPartnerName();
    }
}
