//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    components: { },
    props: {},
    data(){
        return{
            tableData:[{id:1}],
        }
    },
    computed: {
        rowguid:function(){
            return this.getQuery('rowguid')
        }  
    },
    watch:{
        
    },
    methods: {
        cancle: function(){
			this.$emit('close');
		},
        getDetail:function(){
            this.$get(this.$store.getters.partnerUrl + '/channelInfo/'+this.dwcode, {}, function(data) {
                this.dwinfo = data.dwinfo;
                this.dwrateList = data.dwrateList;
                if(data.user){
                    this.user = data.user;
                }
            })
        },
        //上传
        upload:function(){

        },
        del:function(row){
            this.tableData.splice(row,1)
        },
        addFp:function(){
            this.tableData.push({id:1})
        },
      
    },
    mounted:function(){
          
    }
}
