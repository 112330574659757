//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import selectmonth from '../../components/selectmonth'

export default {

    props:[],
    components:{selectmonth},
    data: function(){
        return{
            form:{
                itemAmount:'',
                settAmount:'',
                jbzhzh:''    
            },
            
            pgData:{
               settStart:'',
               settEnd:''
            },
            monthShow:true,
            monthtime:'',
            selectvalue: (this.getGetters('user').isSn == true || this.getQuery('isSn')== 'true') ? '3' : '1',
            loading:false,
            projectList:[],
            projectStr:''
        }
    }, 
    computed:{
        valuetime:{
            get:function(){
                var startTime = this.pgData.settStart || '';
                var endtime = this.pgData.settEnd || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.settStart = '' ;
                    this.pgData.settEnd = '';
                }else{
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.settStart = startTime;
                    this.pgData.settEnd = endtime;
                }
            },
        }  
    },
    watch:{

        selectvalue:function(n,o){
            if(n != o ){
                this.monthtime = '';
                this.pgData.settStart = '';
                this.pgData.settEnd = '';
                this.form.settBidsection = '';
                this.projectStr ='';
                this.projectList = [],
                this.getInfo('','','')
            }
        }
    },

    methods: {
        cancle:function(){
            this.$emit('close');
        },
        getInfo:function(val1,val2,val3){
            var that = this;
            that.$get(`${that.getGetters('settUrl')}/settItem/applyInfo`,{
                settStart:val1,
                settEnd:val2,
                bidsection:val3,
            },function(data){
                that.form.itemAmount= data.itemAmount;
                that.form.settAmount = data.settAmount;
                that.form.jbzhzh = data.jbzhzh;
            })
        },
        submitTip:function(){
                var that = this;
                showConfirm('请核实项目名称，如含有已添加结算的项目系统将自动取消，确定将提交合作商确认结算。', 'warning', () => {
                    that.new_submit();
            });
        },

        new_submit:function(){
            var that =this;
                let obj = {
                    settType:that.selectvalue,
                    itemName:that.form.itemName,
                    settStart:that.pgData.settStart,
                    settEnd:that.pgData.settEnd,
                    appPhone:that.form.appPhone,
                    remark:that.form.remark,
                    settBidsection: that.projectStr
                }
                // let newObj = JSON.stringify(obj); 
                // let token = that.getQuery('token') ? that.getQuery('token') : ''
                // $.ajax({
                //         url:"/bhtong/settmg/settItem/applySett",
                //         type:"POST",
                //         data:newObj,
                //         headers:{'Content-Type': 'application/json','token':token},
                //         success:function(data){
                //             if(data.code == 'v'){
                //                 that.cancle();
                //                 ShowMsg('申请成功','success');
                //             }else{
                //                 ShowMsg(data.msg,'warning');
                //             }
                //         },
                //         error:function(){

                //         }
                // })
                that.$post('/bhtong/settmg/settItem/applySett', obj ,function(data){
                    that.cancle();
                    ShowMsg('申请成功','success');
                },true)
        },
        submit:function(){
            var that =this;
            if(that.selectvalue == '3' && (this.getGetters('user').isfxs == true || this.getQuery('isfxs')== 'true')){
                that.submitTip();
            }else{
                that.new_submit();
            }
            
        },
        changeMonth:function(val){
            if(val){
                let years = val[1].slice(0,4);
                let mons = val[1].slice(5,7);
                let days = val[1].slice(8,10);
                this.pgData.settStart = val[0] + ' ' + '00:00:00';
                if(['01','03','05','07','08','10','12'].indexOf(mons) != -1){
                    days = 31
                    val[1] = years + '-' + mons + '-' + days
                    this.pgData.settEnd = years + '-' + mons + '-' + days + ' ' + '00:00:00'
                } else if (['04','06','05','09','11'].indexOf(mons) != -1){
                    days = 30
                    val[1] = years + '-' + mons + '-' + days
                    this.pgData.settEnd = years + '-' + mons + '-' + days + ' ' + '00:00:00'
                }else{
                    if(years % 4 == 0 && years % 100 !=0 || years % 400 == 0){
                        days = 29
                        val[1] = years + '-' + mons + '-' + days
                        this.pgData.settEnd = years + '-' + mons + '-' + days + ' ' + '00:00:00'
                    }else{
                        days = 28
                        val[1] = years + '-' + mons + '-' + days
                        this.pgData.settEnd = years + '-' + mons + '-' + days + ' ' + '00:00:00'
                    }
                }
                this.getInfo(this.pgData.settStart,this.pgData.settEnd,'');
            }else{
                this.getInfo('','','');
            }
        },
        changeDay:function(val){
            if(val){
                this.getInfo(val[0],val[1],'');
            }else{
                 this.getInfo('','','');
            }
        },
        //项目名称远程搜索
        projectListFilter:function(query){
            if (query !== '') {
                this.loading = true;
                this.$get('/bhtong/ordermg/guaOrder/listSelect',{bidsection:query},function(data){
                    this.projectList = data;
                    this.loading = false;
                });
            } else {
                this.projectList = [];
            }
        }, 
        projectSelect:function(val){
            if(val == ''){
                this.projectList = []
            }
            this.projectStr = val.toString();
            this.getInfo('','',this.projectStr);
        },
    },
    mounted() {
        this.getInfo('','','');
    }
}
